/**
 * 🔍 Browser detection utility
 */
export const getBrowser = () => {
    const ua = window.navigator.userAgent;
    const isChrome = /Chrome\/(\d+)/.exec( ua );

    if ( isChrome ) {
        return {
            name: 'chrome',
            version: parseInt( isChrome[ 1 ], 10 )
        };
    }

    return {
        name: 'other',
        version: 0
    };
};

/**
 * 📦 Legacy feature polyfills for older browsers
 */
const legacyPolyfills = [
    {
        test: () => !window.requestAnimationFrame,
        load: () => import( 'raf/polyfill' )
    },
    {
        test: () => typeof regeneratorRuntime === 'undefined',
        load: () => import( 'regenerator-runtime/runtime' )
    },
    {
        test: () => !window.Promise,
        load: () => import( 'core-js/es/promise' )
    },
    {
        test: () => !window.Symbol,
        load: () => import( 'core-js/es/symbol' )
    },
    {
        test: () => !window.Map,
        load: () => import( 'core-js/es/map' )
    },
    {
        test: () => !window.Set,
        load: () => import( 'core-js/es/set' )
    },
    {
        test: () => !window.WeakMap,
        load: () => import( 'core-js/es/weak-map' )
    },
    {
        test: () => !window.WeakSet,
        load: () => import( 'core-js/es/weak-set' )
    },
    {
        test: () => !String.prototype.padStart,
        load: () => import( 'core-js/es/string/pad-start' )
    },
    {
        test: () => !String.prototype.padEnd,
        load: () => import( 'core-js/es/string/pad-end' )
    },
    {
        test: () => !Object.values,
        load: () => import( 'core-js/es/object/values' )
    },
    {
        test: () => !Object.entries,
        load: () => import( 'core-js/es/object/entries' )
    },
    {
        test: () => !Object.getOwnPropertyDescriptors,
        load: () => import( 'core-js/es/object/get-own-property-descriptors' )
    },
    {
        test: () => !Array.prototype.includes,
        load: () => import( 'core-js/es/array/includes' )
    },
    {
        test: () => !Array.from,
        load: () => import( 'core-js/es/array/from' )
    },
    {
        test: () => !Object.assign,
        load: () => import( 'core-js/es/object/assign' )
    },
    {
        test: () => !String.prototype.includes,
        load: () => import( 'core-js/es/string/includes' )
    },
    {
        test: () => typeof globalThis === 'undefined',
        load: () => import( 'core-js/es/global-this' )
    }
];

/**
 * 🚀 Modern feature polyfills needed regardless of browser
 */
const modernPolyfills = [
    {
        test: () => !window.structuredClone,
        load: () => import( 'core-js/actual/structured-clone' )
    },
    {
        test: () => !Array.prototype.group,
        load: () => import( 'core-js/actual/array/group' )
    },
    {
        test: () => !window.queueMicrotask,
        load: () => import( 'core-js/actual/queue-microtask' )
    },
    // Added missing modern polyfills
    {
        test: () => !Array.from,
        load: () => import( 'core-js/actual/array/from' )
    },
    {
        test: () => !window.Set,
        load: () => import( 'core-js/actual/set' )
    },
    {
        test: () => !window.Promise,
        load: () => import( 'core-js/actual/promise' )
    }
];

/**
 * 📥 Load IE9 and stable polyfills for older browsers
 */
const loadReactPolyfills = async () => {
    const browser = getBrowser();
    if ( browser.name !== 'chrome' || browser.version < 71 ) {
        await Promise.all( [
            import( 'react-app-polyfill/ie9' ),
            import( 'react-app-polyfill/stable' )
        ] );
    }
};

/**
 * 📥 Load all required polyfills based on browser detection
 */
export const loadPolyfills = async () => {
    const browser = getBrowser();
    const polyfillsNeeded = [];

    // Load React polyfills for older browsers
    await loadReactPolyfills();

    // Load legacy polyfills only for older browsers
    if ( browser.name !== 'chrome' || browser.version < 71 ) {
        legacyPolyfills.forEach( polyfill => {
            if ( polyfill.test() ) {
                polyfillsNeeded.push( polyfill.load() );
            }
        } );
    }

    // Load modern polyfills for all browsers
    modernPolyfills.forEach( polyfill => {
        if ( polyfill.test() ) {
            polyfillsNeeded.push( polyfill.load() );
        }
    } );

    // Load all needed polyfills in parallel
    await Promise.all( polyfillsNeeded );
};