import { loadPolyfills } from './polyfills';

/**
 * 🎯 Main application initialization
 */
const init = async () => {
  try {
    // Load polyfills first
    await loadPolyfills();

    // Then load app dependencies
    const [
      { default: App },
      { default: React },
      { default: ReactDOM },
      { default: ErrorBoundary },
      { HelmetProvider }
    ] = await Promise.all( [
      import( './App' ),
      import( 'react' ),
      import( 'react-dom/client' ),
      import( './views/app/errors' ),
      import( 'react-helmet-async' )
    ] );

    // Import CSS
    import( './styles/fonts/font.min.css' );

    // Render app
    const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
    root.render(
      <React.StrictMode>
        <ErrorBoundary>
          <HelmetProvider>
            <React.Suspense fallback={ <></> }>
              <App />
            </React.Suspense>
          </HelmetProvider>
        </ErrorBoundary>
      </React.StrictMode>
    );
  } catch ( error ) {
    console.error( 'Failed to initialize app:', error );
  }
};

// Start the app
init();